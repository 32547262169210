import "./DefaultLayout.less"

import { Layout } from "antd"
import { FC, Suspense } from "react"
import { Outlet } from "react-router-dom"
import { Header } from "./Header"

export const DefaultLayout: FC = () => (
  <Layout className={"default"}>
    <Layout.Header>
      <Header />
    </Layout.Header>
    <Layout.Content>
      <Suspense>
        <Outlet />
      </Suspense>
    </Layout.Content>
  </Layout>
)
export default DefaultLayout
