import { FC, ReactElement, lazy, memo, useEffect } from "react"
import { RouteObject, RouterProvider, createBrowserRouter, useNavigate } from "react-router-dom"
import { useLocalStorage } from "react-use"
import { DefaultLayout } from "../components/layout"
import { App } from "../types"

const Students = lazy<FC>(() => import("./students"))
const Profile = lazy<FC>(() => import("./profile"))
const Groups = lazy<FC>(() => import("./groups"))
const Courseware = lazy<FC>(() => import("./courseware"))
const SignIn = lazy<FC>(() => import("./sign-in"))
const EmailConfirm = lazy<FC>(() => import("./email-confirm"))
const ResetPassword = lazy<FC>(() => import("./reset-password"))
const Reports = lazy<FC>(() => import("./reports"))
const Help = lazy<FC>(() => import("./help"))
const StudentsDashboard = lazy<FC>(() => import("./students-dashboard"))
const Lesson = lazy<FC>(() => import("./lesson"))
const Company = lazy<FC>(() => import("./company"))
type PrivateRouteProps = {
  element: ReactElement
  path?: string
  children?: RouteObject[]
}

const PrivateRoute: FC<PrivateRouteProps> = ({ element }) => {
  const navigate = useNavigate()
  const getUserRole = () => {
    const [user] = useLocalStorage<App.UserLoginResponse>("user")
    return user && user.type
  }

  const userRole = getUserRole()

  useEffect(() => {
    if (location.pathname) {
      switch (userRole) {
        case 99:
          if (
            location.pathname !== "/companies" &&
            !location.pathname.startsWith("/company") &&
            !location.pathname.startsWith("/profile")
          ) {
            navigate("/companies")
          }
          break

        case 2:
          if (location.pathname.startsWith("/lesson") || location.pathname.startsWith("/dashboard")) {
            navigate("/")
          }
          break

        case 1:
          if (location.pathname === "/companies" && location.pathname.startsWith("/company")) {
            navigate("/")
          }
          break

        case 0:
          if (
            !location.pathname.startsWith("/dashboard") &&
            !location.pathname.startsWith("/lesson") &&
            !location.pathname.startsWith("/profile")
          ) {
            navigate("/dashboard")
          }
          break

        default:
          navigate("/sign-in")
          break
      }
    }
  }, [location.pathname, navigate])

  return element
}

const privateRoutes: RouteObject[] = [
  {
    path: "",
    element: <PrivateRoute element={<DefaultLayout />} />,
    children: [
      {
        path: "dashboard",
        element: <StudentsDashboard />,
        children: [
          {
            path: ":id",
            element: <StudentsDashboard />,
          },
        ],
      },
      {
        path: "lesson/:id",
        element: <Lesson />,
      },
      {
        path: "companies",
        element: <Company />,
      },
      {
        path: "company",
        element: <Company />,
        children: [
          {
            path: ":id",
            element: <Company />,
          },
        ],
      },
      {
        path: "",
        element: <Students />,
      },
      {
        path: "students",
        element: <Students />,
        children: [
          {
            path: "assign/users/:id",
            element: <Students />,
          },
        ],
      },
      {
        path: "profile",
        element: <Profile />,
        children: [
          {
            path: ":id",
            element: <Profile />,
          },
        ],
      },
      {
        path: "course",
        element: <Courseware />,
        children: [
          {
            path: ":id",
            element: <Courseware />,
          },
        ],
      },
      {
        path: "courseware",
        element: <Courseware />,
      },
      {
        path: "group",
        element: <Groups />,
        children: [
          {
            path: "assign-curriculum/:id",
            element: <Groups />,
          },
          {
            path: ":id",
            element: <Groups />,
          },
        ],
      },
      {
        path: "groups",
        element: <Groups />,
      },
      {
        path: "reports",
        element: <Reports />,
        children: [
          {
            path: "progress/:id",
            element: <Reports />,
          },
        ],
      },
      {
        path: "help",
        element: <Help />,
      },
    ],
  },
]

const publicRoutes: RouteObject[] = [
  {
    element: <DefaultLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
      {
        path: "request-email-confirm",
        element: <EmailConfirm />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
    ],
  },
]

const routes = [...publicRoutes, ...privateRoutes]

const router = createBrowserRouter(routes)

const PageProvider = memo(() => <RouterProvider router={router} />)

export default PageProvider
