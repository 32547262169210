import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from "@apollo/client"
import { createContext, memo, useContext, type FC, type PropsWithChildren } from "react"
import { useToggle } from "react-use"
import introspection from "../../graphql"
import PageProvider from "../../pages"
import { AvstarContextProvider } from "../../context/Avstar"

type AppProps = {
  app: { api: boolean }
  burger: { opened: boolean; toggle: VoidFunction }
  nav: { visible: boolean; toggleNav: VoidFunction }
}

const defaultValue: AppProps = {
  app: { api: false },
  burger: { opened: false, toggle: () => ({}) },
  nav: { visible: false, toggleNav: () => ({}) },
}

const Context = createContext(defaultValue)

type ContextProviderProps = PropsWithChildren<Partial<AppProps>>

const ContextProvider: FC<ContextProviderProps> = ({ children, ...props }) => {
  const [opened, toggle] = useToggle(false)
  const [visible, toggleNav] = useToggle(true)

  return (
    <Context.Provider
      value={{
        ...defaultValue,
        burger: { opened, toggle },
        nav: { visible, toggleNav },
        ...props,
      }}
    >
      {children}
    </Context.Provider>
  )
}

const apiUrl = `${import.meta.env.WEBSITE_API_URL ?? "/graphql"}` as const

const clientOptions: ConstructorParameters<typeof ApolloClient>[0] = {
  link: createHttpLink({
    uri: apiUrl,
    credentials: "same-origin",
  }),
  connectToDevTools: import.meta.env.DEV,
  queryDeduplication: true,
  cache: new InMemoryCache({
    resultCaching: import.meta.env.PROD,
    possibleTypes: introspection.possibleTypes,
  }),
}

const apolloClient = new ApolloClient(clientOptions)

const App: FC = memo(() => (
  <ApolloProvider client={apolloClient}>
   <AvstarContextProvider>
    <ContextProvider>
      <PageProvider />
    </ContextProvider>
   </AvstarContextProvider>
  </ApolloProvider>
))
const useApp = () => useContext(Context)

export { ContextProvider, useApp }

export default App
