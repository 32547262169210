import { MenuProps, Row } from "antd"
import { FC } from "react"
import { useMediaQuery } from "react-responsive"
import { NavLink } from "react-router-dom"
import { useLocalStorage } from "react-use"
import Navigation from "../../components/ui/menu/Navigation"
import { App } from "../../types"
import { SvgIcon } from "../icon"

const WebsiteMenu: FC<MenuProps> = ({ mode, onClick }) => {
  const isTablet = useMediaQuery({ query: "(max-width: 1180px)" })
  const [user] = useLocalStorage<App.StudentEntity>("user")
  const isGroupAdmin = user?.type === 1
  const deprecatedGroupAdminLinks = ["/groups", "/courseware"]
  const menu = [
    {
      key: "/students",
      label: (
        <NavLink className={isTablet ? "mobile-menu-item" : ""} to={"/students"}>
          <Row wrap={false} align={"middle"}>
            <SvgIcon type={"studentHatIcon"} /> Students
          </Row>
        </NavLink>
      ),
    },
    {
      key: "/groups",
      label: (
        <NavLink className={isTablet ? "mobile-menu-item" : ""} to={"/groups"}>
          <Row wrap={false} align={"middle"}>
            <SvgIcon type={"groupsIcon"} /> Groups
          </Row>
        </NavLink>
      ),
    },
    {
      key: "/courseware",
      label: (
        <NavLink className={isTablet ? "mobile-menu-item" : ""} to={"/courseware"}>
          <Row wrap={false} align={"middle"}>
            <SvgIcon type={"fileIcon"} /> Courseware
          </Row>
        </NavLink>
      ),
    },
    {
      key: "/reports",
      label: (
        <NavLink className={isTablet ? "mobile-menu-item" : ""} to={"/reports"}>
          <Row wrap={false} align={"middle"}>
            <SvgIcon type={"chartIcon"} /> Reports
          </Row>
        </NavLink>
      ),
    },
    {
      key: "/help",
      label: (
        <NavLink className={isTablet ? "mobile-menu-item" : ""} to={"/help"}>
          <Row wrap={false} align={"middle"}>
            <SvgIcon type={"infoIcon"} /> Help
          </Row>
        </NavLink>
      ),
    },
  ]

  const filteredMenu = isGroupAdmin ? menu.filter(item => !deprecatedGroupAdminLinks.includes(item.key)) : menu

  return <Navigation onClick={onClick} mode={mode} items={filteredMenu} />
}

export default WebsiteMenu
