import { Avatar, Button, Dropdown, Row } from "antd"
import { FC, useMemo } from "react"
import { useNavigate } from "react-router"
import { NavLink } from "react-router-dom"
import { useLocalStorage, useLocation } from "react-use"
import { SvgIcon } from "../../handlers/icon"
import WebsiteMenu from "../../handlers/menu"
import { App } from "../../types"
import { useApp } from "../app"
import { Logo } from "../ui/logo"
import { BurgerMenu } from "../ui/menu/BurgerMenu"
import "./Header.less"
const Header: FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [user, setUser] = useLocalStorage<App.UserLoginResponse | null>("user")
  const handleSignOut = () => {
    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
    user && setUser(null)
    navigate("/sign-in")
  }

  const getUserInitials = useMemo(() => {
    if (user) {
      const { firstName, lastName } = user
      return `${firstName[0]}${lastName[0]}`
    }
    return ""
  }, [user])

  const handleGroupAdminOption = useMemo(() => {
    if (location.pathname === "/dashboard") {
      return {
        key: "0",
        label: (
          <NavLink to={`/students`}>
            <SvgIcon type={"homeIcon"} fill={"#1E1E1E"} />
            Switch to Manager
          </NavLink>
        ),
      }
    } else {
      return {
        key: "0",
        label: (
          <NavLink to={`/dashboard`}>
            <SvgIcon type={"studentHatIcon"} fill={"#1E1E1E"} />
            Switch to Student
          </NavLink>
        ),
      }
    }
  }, [user, location.pathname])

  const items = [
    user?.type === 1 ? handleGroupAdminOption : null,
    {
      key: "1",
      label: (
        <NavLink to={`/profile/${btoa(`${user?.userID}`)}`}>
          <SvgIcon type={"eyeIcon"} fill={"#1E1E1E"} />
          View Profile
        </NavLink>
      ),
    },
    {
      key: "2",
      label: (
        <Button onClick={handleSignOut} className={"transparent"}>
          <SvgIcon type={"signOut"} fill={"#B25758"} />
          <span className={"text-danger"}>Log Out</span>
        </Button>
      ),
    },
  ]

  const { nav } = useApp()
  return (
    <Row className={"container-header"} wrap={false} align={"middle"} justify={"center"}>
      {nav.visible && <WebsiteMenu mode={"horizontal"} />}
      {nav.visible && <BurgerMenu />}
      <Logo />

      {user && user.type !== 99 ? (
        <Dropdown.Button
          className={"user-avatar"}
          icon={<Avatar size={40}>{getUserInitials}</Avatar>}
          trigger={["click"]}
          menu={{ items }}
          placement={"bottomLeft"}
        />
      ) : (
        <Button type={"ghost"} onClick={handleSignOut} className={"single"}>
          <SvgIcon type={"signOut"} fill={"#FFFFFF"} />
          <span>Log Out</span>
        </Button>
      )}
    </Row>
  )
}

export { Header }
